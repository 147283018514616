import React from 'react';
import ButtonIcon from '@hpstellar/core/visId/ButtonIcon';
import { Search } from '@hpstellar/vis-icons';

import { getSrpTypeFromUrl } from '../util';

export default props => {
    const {
        updatedFromService,
        productSrpLink,
        getAutocompleteLinkData,
        searchKey,
        pStoreID,
        history,
        closeModalAndInputBar,
        singleProductUrl,
        redirectLocation
    } = props || {};
    const extraProps = updatedFromService
        ? {
              onClick: () => {
                  try {
                      let autocompleteData = !singleProductUrl && getAutocompleteLinkData(searchKey || '', pStoreID);
                      let srpType = autocompleteData && getSrpTypeFromUrl();
                      let productSearchLink = productSrpLink || (autocompleteData && autocompleteData.productSrpLink);
                      let autocompleteLink = (srpType && autocompleteData && ({
                        'content': autocompleteData.contentSrpLink,
                        'support': autocompleteData.supportSrpLink
                      })[srpType]) || productSearchLink;

                      let link =
                          redirectLocation && redirectLocation.length > 0
                              ? redirectLocation
                              : singleProductUrl || autocompleteLink;
                      link && history.push(link);
                      closeModalAndInputBar();
                  } catch (e) {}
              },
              className: 'actionIcon autocomplete-search-linked-button',
          }
        : {};

    return (
        <ButtonIcon
            icon={<Search />}
            variant="primary"
            theme="dark"
            size="large"
            tabindex={0}
            aria-label="Submit"
            className="actionIcon"
            {...extraProps}
        />
    );
};